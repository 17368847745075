import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../services/api';
import AuthLayout from './AuthLayout';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ConstellationBackground from './ConstellationBackground';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams();
  const navigate = useNavigate();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
 
  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('reset_password');
      setRecaptchaToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      const recaptchaToken = await executeRecaptcha('reset_password');

      await API.post('/auth/reset-password', { token, password, recaptchaToken });
      alert('Password has been reset successfully.');
      navigate('/login');
      window.location.reload();
    } catch (error) {
      console.error(error);
      alert('Error resetting password.');
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <ConstellationBackground />
    <AuthLayout>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4} width="100%">
        <img src="/logo.png" alt="Foreign Media Accreditation System Logo" style={{ width: '100px', marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom align="center" fontWeight={500}>
          FOMRAS
        </Typography>
        <Typography variant="h6" gutterBottom align="center" fontWeight={100}>
          Foreign Media Representatives Accreditation System
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Please enter your new password below.
        </Typography>
      </Box>
      <Box
      justifyContent="center" 
      style={{ 
          backgroundColor: "white", 
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          borderRadius: "8px", 
          padding: "16px",
          width: "100%",
      }}
      >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label="New Password"
          variant="outlined"
          fullWidth
          margin="normal"
          name="password"
          type="password"
          value={password}
          onChange={handleChange}
        />
        <TextField
          label="Confirm Password"
          variant="outlined"
          fullWidth
          margin="normal"
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleChange}
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Reset Password
          </Button>
        </Box>
      </form>
      </Box>
    </AuthLayout>
    </div>
  );
};

const ResetPasswordWithReCaptcha  = () => {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <ResetPassword />
      </GoogleReCaptchaProvider>
    );
  };
  
  export default ResetPasswordWithReCaptcha;
  
