import axios from 'axios';
import { isAuthenticated, getToken } from '../utils/auth';

const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5001/api' : '/api';
const API = axios.create({
  baseURL: baseUrl, 
});

API.interceptors.request.use(
    (config) => {
      if (isAuthenticated()) {
        const token = getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default API;
