import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from '@mui/material';
import API from '../services/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import { Hub } from '@mui/icons-material';

const Requests = () => {
  const [requests, setRequests] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filePreviews, setFilePreviews] = useState({});
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState('');
  const [additionalInfoModalOpen, setAdditionalInfoModalOpen] = useState(false);
  const [additionalInfoText, setAdditionalInfoText] = useState('');
  const [validUntilModalOpen, setValidUntilModalOpen] = useState(false);
  const [validUntilDate, setValidUntilDate] = useState('');
  const [declineModalOpen, setDeclineModalOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [electronicOfficeId, setElectronicOfficeId] = useState('');
  

  useEffect(() => {
    fetchRequests();
  }, [page, rowsPerPage, searchTerm, filterStatus]);

  const fetchRequests = async () => {
    try {
      const response = await API.get('/admin/requests', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          searchTerm,
          filterStatus,
        },
      });
      setRequests(response.data.requests);
      setTotalRequests(response.data.total);
    } catch (error) {
      console.error('Error fetching requests:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
    setPage(0);
  };

  const handleViewRequest = async (request) => {
    setSelectedRequest(request);
    setOpenModal(true);

    try {
      const userProfileResponse = await API.get(`/user/profile/${request.userId._id}`);
      setSelectedUser(userProfileResponse.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }

    const fetchFilePreview = async (fileName, field) => {
      try {
        const response = await API.get(`/request/files/${fileName}`, {
          params: { targetUserId: request.userId._id },
          responseType: 'blob',
        });
        const url = URL.createObjectURL(response.data);
        setFilePreviews((prev) => ({ ...prev, [field]: url }));
      } catch (error) {
        console.error(`Error fetching ${field} preview:`, error);
      }
    };

    if (request.passport_photo) fetchFilePreview(request.passport_photo, 'passport_photo');
    if (request.proof_photo) fetchFilePreview(request.proof_photo, 'proof_photo');
    if (request.personal_photo) fetchFilePreview(request.personal_photo, 'personal_photo');
    if (request.proof_of_address_in_rs) fetchFilePreview(request.proof_of_address_in_rs, 'proof_of_address_in_rs');
    if (request.professional_cv) fetchFilePreview(request.professional_cv, 'professional_cv');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRequest(null);
    setSelectedUser(null);
    setFilePreviews({});
  };

  const handleAction = async (request, action) => {
    if (!request || !action) return;

    if(action === 'saveToBlockchain') {
      setIsLoading(true);
      try {
        await API.put(`/admin/requests/${request._id}/save-to-blockchain`);
        await fetchRequests();
      } catch (error) {
        console.error('Error saving request to blockchain:', error);
      } finally {
        setIsLoading(false);
      }
      return;
    }

    if (action === 'approve') {
      setSelectedRequest(request);
      setValidUntilModalOpen(true);
      return;
    } else if (action === 'decline') {
      setSelectedRequest(request);
      setDeclineModalOpen(true);
      return;
    }

    setIsLoading(true);

    try {
      if (action === 'requestData') {
        setSelectedRequest(request);
        setAdditionalInfoModalOpen(true);
      }
      await fetchRequests();
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleApproveWithDate = async () => {
    if (!validUntilDate) {
      alert('Please select a valid date');
      return;
    }

    setIsLoading(true);

    try {
      await API.put(`/admin/requests/`, {
        id: selectedRequest._id,
        status: 'APPROVED',
        valid_until: validUntilDate,
        electronic_office_id: electronicOfficeId,
      });
      setValidUntilModalOpen(false);
      setSelectedRequest(null);
      setValidUntilDate('');
      await fetchRequests();
    } catch (error) {
      console.error('Error approving request with date:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclineWithReason = async () => {
    if (!declineReason) {
      alert('Please provide a reason for decline');
      return;
    }

    setIsLoading(true);

    try {
      await API.put(`/admin/requests/`, {
        id: selectedRequest._id,
        status: 'DECLINED',
        decline_reason: declineReason,
      });
      setDeclineModalOpen(false);
      setSelectedRequest(null);
      setDeclineReason('');
      await fetchRequests();
    } catch (error) {
      console.error('Error declining request with reason:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestMoreData = async () => {
    if (!selectedRequest || !additionalInfoText) return;

    setIsLoading(true);
    try {
      await API.post(`/admin/requests/${selectedRequest._id}/request-data`, { additionalInfo: additionalInfoText });
      setAdditionalInfoModalOpen(false);
      setAdditionalInfoText('');
      setSelectedRequest(null);
      await fetchRequests();
    } catch (error) {
      console.error('Error requesting more data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageClick = (src) => {
    setImageModalSrc(src);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageModalSrc('');
  };

  const getStatusButtonStyle = (status) => {
    switch (status) {
      case 'PENDING':
        return { backgroundColor: '#f0ad4e', color: 'white' }; // Orange
      case 'APPROVED':
        return { backgroundColor: '#5cb85c', color: 'white' }; // Green
      case 'DECLINED':
        return { backgroundColor: '#d9534f', color: 'white' }; // Red
      case 'MORE_INFO_NEEDED':
        return { backgroundColor: '#5bc0de', color: 'white' }; // Light Blue
      case 'RESUBMITTED':
        return { backgroundColor: '#0275d8', color: 'white' }; // Dark Blue
        case 'EXPIRED':
        return { backgroundColor: '#d9534f', color: 'white' }; // Dark Blue
      default:
        return {};
    }
  };

  const renderRequestDetails = (request, user) => {
    if (!request || !user) return null;

    const renderFilePreview = (file, field) => {
      const url = filePreviews[field];
      if (!url) return null;

      const isPdf = file.endsWith('.pdf');

      if (isPdf) {
        return (
          <Typography variant="body2">
            <a href={url} target="_blank" rel="noopener noreferrer">
              {file}
            </a>
          </Typography>
        );
      }
      return (
        <img
          src={url}
          alt={file}
          style={{ width: '100px', height: '100px', marginRight: '10px', cursor: 'pointer' }}
          onClick={() => handleImageClick(url)}
        />
      );
    };

    return (
      <Box p={4} bgcolor="background.paper" style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}>
        <IconButton
          onClick={handleCloseModal}
          style={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        {request.status === 'MORE_INFO_NEEDED' && (
          <Box mt={2} p={2} bgcolor="orange" borderRadius="8px">
            <Typography variant="h6" gutterBottom>
              Корисник је обавештен да је потребно допунити захтев
            </Typography>
            <Typography variant="body1"> {request.more_info_reason}</Typography>
          </Box>
        )}
        {request.status === 'RESUBMITTED' && (
          <Box mt={2} p={2} bgcolor="orange" borderRadius="8px">
            <Typography variant="h6" gutterBottom>
              Корисник је био у обавези да обезбеди наведене информације/изменe
            </Typography>
            <Typography variant="body1"> {request.more_info_reason}</Typography>
          </Box>
        )}
        {request.status === 'DECLINED' && (
          <Box mt={2} p={2} bgcolor="red" borderRadius="8px">
            <Typography variant="h6" gutterBottom>
              Захтев одбијен из следећих разлога:
            </Typography>
            <Typography variant="body1">{request.decline_reason}</Typography>
          </Box>
        )}
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" gutterBottom>
          Лични Подаци
        </Typography>
        <Typography variant="body1"><strong>Име:</strong> {user.first_name} {user.last_name}</Typography>
        <Typography variant="body1"><strong>Е-маил:</strong> {user.email}</Typography>
        <Typography variant="body1"><strong>Телефон:</strong> {user.phone}</Typography>
        <Typography variant="body1"><strong>Адреса:</strong> {user.address}, {user.city}, {user.country}</Typography>
        <Typography variant="body1"><strong>Датум рођења:</strong> {new Date(user.date_of_birth).toLocaleDateString()}</Typography>
        <Typography variant="body1"><strong>Држављанство:</strong> {user.citizenship}</Typography>
        <Typography variant="body1"><strong>Пол:</strong> {user.gender}</Typography>
        <Typography variant="body1"><strong>Пасош број:</strong> {user.passport_number}</Typography>
        <Typography variant="body1"><strong>Пасош издат у:</strong> {user.passport_issued_in}</Typography>
        <Typography variant="body1"><strong>Пасош истиче:</strong> {new Date(user.passport_expires).toLocaleDateString()}</Typography>


        

        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" gutterBottom>
          Додатни Подаци
        </Typography>
        <Typography variant="body1"><strong>Тип захтева:</strong> {request.type}</Typography>
        <Typography variant="body1"><strong>Адреса у Србији:</strong> {request.address_in_serbia}</Typography>
        <Typography variant="body1"><strong>Планиран останак у Србији до:</strong> {new Date(request.intended_residence_in_serbia).toLocaleDateString()}</Typography>
        <Typography variant="body1"><strong>Медијско представништво:</strong> {request.editorial_office_name}</Typography>
        <Typography variant="body1"><strong>Адреса медијског преставништва:</strong> {request.editorial_office_address}</Typography>
        <Typography variant="body1"><strong>Град медијског преставништва:</strong> {request.editorial_office_city}</Typography>
        <Typography variant="body1"><strong>Држава медијског преставништва:</strong> {request.editorial_office_country}</Typography>
        <Typography variant="body1"><strong>Телефон медијског преставништва:</strong> {request.editorial_office_phone}</Typography>
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" gutterBottom>
          Документа
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box textAlign="center">
            <Typography variant="body1"><strong>Пасош</strong></Typography>
            {renderFilePreview(request.passport_photo, 'passport_photo')}
          </Box>
          <Box textAlign="center">
            <Typography variant="body1"><strong>Слика</strong></Typography>
            {renderFilePreview(request.personal_photo, 'personal_photo')}
          </Box>
        </Box>

        <Box mt={2} textAlign="center">
          <Typography variant="body1"><strong>Пропратно писмо одговорног лица/медија</strong></Typography>
          {renderFilePreview(request.proof_photo, 'proof_photo')}
        </Box>

        <Box mt={2} textAlign="center">
          <Typography variant="body1"><strong>Професионална биографија</strong></Typography>
          {request.professional_cv ? renderFilePreview(request.professional_cv, 'professional_cv') : '/'}
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body1"><strong>Доказ о пријави боравка</strong></Typography>
          {request.proof_of_address_in_rs ? renderFilePreview(request.proof_of_address_in_rs, 'proof_of_address_in_rs') : '/'}
        </Box>


      </Box>
    );
  };

  return (
    <Box mt={4} mx={2}>
      <Card>
        <CardHeader title="Захтеви" />
        <CardContent>
          <Box mb={2} display="flex">
            <TextField
              label="Претрага"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              halfwidth="true"
            />
            <FormControl variant="outlined" style={{ marginLeft: '16px', width: '100px' }}>
              <InputLabel>Статус</InputLabel>
              <Select
                value={filterStatus}
                onChange={handleFilterStatusChange}
                label="Status"
              >
                <MenuItem value="">Сви</MenuItem>
                <MenuItem value="PENDING">У процесу</MenuItem>
                <MenuItem value="APPROVED">Одобрено</MenuItem>
                <MenuItem value="DECLINED">Одбијено</MenuItem>
                <MenuItem value="MORE_INFO_NEEDED">Потребна допуна</MenuItem>
                <MenuItem value="RESUBMITTED">Допуњено</MenuItem>
                <MenuItem value="EXPIRED">Истекло</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ИД</TableCell>
                  <TableCell>еПисарница</TableCell>
                  <TableCell>Тип</TableCell>
                  <TableCell>Име</TableCell>
                  <TableCell>Медијска кућа</TableCell>
                  <TableCell>Држава</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Опције</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requests.map((request) => (
                  <TableRow key={request._id}>
                    <TableCell>{request._id}</TableCell>
                    <TableCell>{request.electronic_office_id ? request.electronic_office_id : '-'}</TableCell>
                    <TableCell>{
                    (request.type === 'representative') ? 'Акредитација' : 'Регистрација Представништва'
                    }</TableCell>
                    <TableCell>{request.userId.first_name} {request.userId.last_name}</TableCell>
                    <TableCell>{request.editorial_office_name}</TableCell>
                    <TableCell>{request.editorial_office_country}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        disabled
                        style={getStatusButtonStyle(request.status)}
                      >
                        {request.status === 'PENDING'
                          ? 'У процесу'
                          : request.status === 'APPROVED'
                          ? 'Одобрено'
                          : request.status === 'DECLINED'
                          ? 'Одбијено'
                          : request.status === 'MORE_INFO_NEEDED'
                          ? 'Потребна допуна'
                          : request.status === 'RESUBMITTED'
                          ? 'Допуњено'
                          : request.status === 'EXPIRED'
                          ? 'Истекло'
                          : 'Непознат статус'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Детаљи">
                        <IconButton onClick={() => handleViewRequest(request)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {(request.status === 'PENDING' || request.status === 'RESUBMITTED') && (
                        <>
                          <Tooltip title="Одобри">
                            <IconButton onClick={() => handleAction(request, 'approve')}>
                              <CheckIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Одбиј захтев">
                            <IconButton onClick={() => handleAction(request, 'decline')}>
                              <BlockIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Захтевај више података">
                            <IconButton onClick={() => handleAction(request, 'requestData')}>
                              <EmailIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      {/* {(request.status === 'APPROVED' && !request.stored_on_blockchain) && (
                        <>
                          <Tooltip title="Сачувај на блокчејну">
                            <IconButton onClick={() => handleAction(request, 'saveToBlockchain')}>
                              <Hub />
                            </IconButton>
                          </Tooltip>
                        </>
                      )} */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRequests}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </CardContent>
      </Card>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box>{renderRequestDetails(selectedRequest, selectedUser)}</Box>
      </Modal>
      <Modal open={imageModalOpen} onClose={handleImageModalClose}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.8)"
          onClick={handleImageModalClose}
        >
          <img src={imageModalSrc} alt="Full size" style={{ maxHeight: '90%', maxWidth: '90%' }} />
        </Box>
      </Modal>
      <Modal open={additionalInfoModalOpen} onClose={() => setAdditionalInfoModalOpen(false)}>
        <Box
          p={4}
          bgcolor="background.paper"
          style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}
        >
          <Typography variant="h6" gutterBottom>
            Захтев за дораду
          </Typography>
          <TextField
            label="Унесите потребне акције корисника"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={additionalInfoText}
            onChange={(e) => setAdditionalInfoText(e.target.value)}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleRequestMoreData}>
              Пошаљи захтев
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={validUntilModalOpen} onClose={() => setValidUntilModalOpen(false)}>
        <Box
          p={4}
          bgcolor="background.paper"
          style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}
        >
          <Typography variant="h6" gutterBottom>
            Наведите датум истека Акредитације
          </Typography>
          <Divider style={{ margin: '20px 0' }} />
          <TextField
            label="Важи до"
            type="date"
            variant="outlined"
            fullWidth
            value={validUntilDate}
            onChange={(e) => setValidUntilDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Divider style={{ margin: '20px 0' }} />
          <Typography variant="h6" gutterBottom>
            Унесите број из еПисарнице
          </Typography>
          <Divider style={{ margin: '20px 0' }} />
          <TextField
            label="Заводни број"
            type="string"
            variant="outlined"
            fullWidth
            value={electronicOfficeId}
            onChange={(e) => setElectronicOfficeId(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleApproveWithDate}>
              Одобри
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={declineModalOpen} onClose={() => setDeclineModalOpen(false)}>
        <Box
          p={4}
          bgcolor="background.paper"
          style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}
        >
          <Typography variant="h6" gutterBottom>
            Наведите разлог за одбијање захтева
          </Typography>
          <Divider style={{ margin: '20px 0' }} />
          <TextField
            label="Разлог одбијања"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={declineReason}
            onChange={(e) => setDeclineReason(e.target.value)}
          />
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleDeclineWithReason}>
              Пошаљи
            </Button>
          </Box>
        </Box>
      </Modal>
      {isLoading && (
        <Modal open={isLoading}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="rgba(0, 0, 0, 0.5)"
          >
            <CircularProgress />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Requests;
