import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Header = ({ role }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const menuItems = role === 'USER' ? [
    { text: 'My Requests', path: '/dashboard' },
    { text: 'My Profile', path: '/profile' },
    { text: 'My Accreditation', path: '/my-id' },
    { text: 'Support', path: '/support' },
    { text: 'Documentation', path: '/documentation' },
    { text: 'Logout', path: '/logout' }
  ] : [
    { text: 'Преглед', path: '/dashboard' },
    { text: 'Корисници', path: '/admin/users' },
    { text: 'Захтеви', path: '/admin/requests' },
    { text: 'Подршка', path: '/support' },
    { text: 'Документација', path: '/documentation' },
    { text: 'Излаз', path: '/logout' }
  ];

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
    setDrawerOpen(false);
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: '#0072ce' }}> {/* Serbian blue */}
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
          <img src='/logo-transparent.png' alt="Logo" style={{ width: '60px', marginRight: '10px' }} />
          <Typography variant={isMobile ? 'body1' : 'h6'} style={{ flexGrow: 1 }}>
            {role === 'USER' ? 'Foreign Media Representatives Accreditation System' : 'Систем за акредитацију представника страних медија'}
        </Typography>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {menuItems.map((item, index) => (
            <ListItem button key={index} onClick={() => handleMenuItemClick(item.path)}>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Header;
