import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';

const Dashboard = () => {
  const { user } = useContext(AuthContext);

  return (
    <Box mt={4} mx={2} display="flex" justifyContent="center">
      <Card style={{ width: '100%', maxWidth: '1200px' }}>
        {user.role === 'USER' && (
          <>
            <CardHeader title="My Requests" />
            <CardContent>
              <UserDashboard />
            </CardContent>
          </>
        )}
        {user.role === 'ADMIN' && (
          <>
            <CardHeader title="Преглед" />
            <CardContent>
              <AdminDashboard />
            </CardContent>
          </>
        )}
      </Card>
    </Box>
  );
};

export default Dashboard;
