import React from 'react';
import { Container, Box, Typography } from '@mui/material';

const AuthLayout = ({ children }) => {
  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        px={3}
      >
        
        {children}
      </Box>
    </Container>
  );
};

export default AuthLayout;
