import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Card, CardContent, Divider } from '@mui/material';
import API from '../services/api';
import QRCode from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Accreditation = () => {
  const [accreditation, setAccreditation] = useState(null);
  const [filePreviews, setFilePreviews] = useState({});

  useEffect(() => {
    const fetchAccreditation = async () => {
      try {
        const response = await API.get('/user/requests?filterStatus=APPROVED');
        setAccreditation(response.data);

        // Fetch file previews
        const previews = {};
        const personalPhoto = response.data.requests[0].personal_photo;
        const userId = response.data.requests[0].userId._id;

        if (personalPhoto) {
          const imageResponse = await API.get(`/request/files/${personalPhoto}`, {
            params: { targetUserId: userId },
            responseType: 'blob',
          });
          previews.personal_photo = URL.createObjectURL(imageResponse.data);
        }

        setFilePreviews(previews);

      } catch (error) {
        console.error('Error fetching accreditation:', error);
      }
    };

    fetchAccreditation();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById('accreditationCard'),
  });

  const handleSaveAsImage = () => {
    const card = document.getElementById('accreditationCard');
    html2canvas(card).then((canvas) => {
      canvas.toBlob((blob) => {
        saveAs(blob, 'accreditation.png');
      });
    });
  };

  const handleSaveAsPDF = () => {
    const card = document.getElementById('accreditationCard');
    html2canvas(card).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('accreditation.pdf');
    });
  };

  if (!accreditation) {
    return <div>Loading...</div>;
  }

  if (accreditation.total === 0) {
    return (
      <Box mt={4} display="flex" justifyContent="center">
        <Card style={{ padding: '20px', textAlign: 'center' }}>
          <CardContent>
            <Typography variant="h5">No active accreditation found.</Typography>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box mt={4} display="flex" flexDirection="column" alignItems="center">
      <Card
        id="accreditationCard"
        style={{
          padding: '20px',
          textAlign: 'center',
          border: '5px solid #ccc',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '300px', // Adjusted for badge size
          maxHeight: '500px', // Adjusted for badge size
          position: 'relative',
        }}
      >
        <CardContent>
          <Box display="flex" style={{ position: 'absolute', top: '10px', left: '10px' }}>
            <img src="/logo.png" alt="App Logo" style={{ width: '30px', marginRight: '8px' }} />
            <Typography variant="body2" style={{ fontWeight: 100, textAlign: 'left', marginTop: 5 }}>
              Ministry of Information and Telecommunications
            </Typography>
          </Box>
          <Divider style={{ margin: '30px 0' }} />
          <Box display="flex" justifyContent="center">
            {filePreviews.personal_photo && (
              <img
                src={filePreviews.personal_photo}
                alt="Personal"
                style={{ width: '120px', height: '120px', marginBottom: '10px' }}
              />
            )}
          </Box>
          <Typography variant="h5">
            {accreditation.requests[0].userId.first_name} {accreditation.requests[0].userId.last_name}
          </Typography>
          <Typography variant="body1" gutterBottom>{accreditation.requests[0].editorial_office_name}</Typography>
          <Typography variant="body2" gutterBottom>{accreditation.requests[0].editorial_office_country}</Typography>

          <Box mt={2}>
            <QRCode value={`https://fomras.mit.gov.rs/verify/${accreditation.requests[0]._id}`} size={100} />
          </Box>
          <Divider style={{ margin: '30px 0' }} />
          <Typography variant="body2">
            Valid until: {new Date(accreditation.requests[0].valid_until).toLocaleDateString()}
          </Typography>
        </CardContent>
      </Card>
      <Box mt={2}>
        <Button onClick={handlePrint} variant="contained" color="primary">
          Print
        </Button>
        <Button onClick={handleSaveAsImage} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
          Save as Image
        </Button>
        <Button onClick={handleSaveAsPDF} variant="contained" style={{ marginLeft: '10px' }}>
          Save as PDF
        </Button>
      </Box>
    </Box>
  );
};

export default Accreditation;
