import React from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  const flagUrl = `https://flagcdn.com/w20/${data.value.toLowerCase()}.png`;

  return (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <img src={flagUrl} alt={data.label} style={{ marginRight: 10, width: 20 }} />
      {data.label}
    </div>
  );
};

const CountrySelect = ({ value, onChange, placeholder }) => {
  const options = countryList().getData();

  return (
    <Select
      options={options}
      value={options.find(option => option.label === value) || null}
      onChange={(option) => onChange(option ? option.label : '')}
      placeholder={placeholder}
      components={{ Option: CustomOption }}
      styles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: 'white',
          border: '1px solid #ced4da',
          borderRadius: '4px',
          minHeight: '56px',
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: 'white',
          zIndex: 9999,
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#007bff' : 'white',
          color: state.isSelected ? 'white' : 'black',
          padding: 10,
        }),
      }}
      isClearable
      required
    />
  );
};

export default CountrySelect;
