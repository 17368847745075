import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Modal,
  Divider,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import API from '../services/api';

const UserDashboard = () => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [filePreviews, setFilePreviews] = useState({});
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState('');

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await API.get('/user/requests', {
          params: {
            page: page + 1,
            limit: rowsPerPage,
            searchTerm,
            filterStatus,
          },
        });
        setRequests(response.data.requests || []); // Ensure requests is an array
      } catch (error) {
        console.error('Error fetching user requests:', error);
      }
      console.log(requests);
    };

    const fetchProfileData = async () => {
      try {
        const response = await API.get('/user/profile');
        setProfileData(response.data);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchRequests();
    fetchProfileData();
  }, [page, rowsPerPage, searchTerm, filterStatus]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0); // Reset to first page on search term change
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
    setPage(0); // Reset to first page on filter change
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page on rows per page change
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this request?');
    if (confirmed) {
      try {
        await API.delete(`/request/${id}`);
        setRequests(requests.filter((request) => request._id !== id));
      } catch (error) {
        console.error('Error deleting request:', error);
        alert('Failed to delete request.');
      }
    }
  };

  const handleView = async (request) => {
    setSelectedRequest(request);
    setOpenModal(true);

    const fetchFilePreview = async (fileName, field) => {
      try {
        const response = await API.get(`/request/files/${fileName}`, {
          responseType: 'blob',
        });
        const url = URL.createObjectURL(response.data);
        setFilePreviews((prev) => ({ ...prev, [field]: url }));
      } catch (error) {
        console.error(`Error fetching ${field} preview:`, error);
      }
    };

    if (request.passport_photo) fetchFilePreview(request.passport_photo, 'passport_photo');
    if (request.proof_photo) fetchFilePreview(request.proof_photo, 'proof_photo');
    if (request.personal_photo) fetchFilePreview(request.personal_photo, 'personal_photo');
    if (request.proof_of_address_in_rs) fetchFilePreview(request.proof_of_address_in_rs, 'proof_of_address_in_rs');
    if (request.professional_cv) fetchFilePreview(request.professional_cv, 'professional_cv');
  };

  const handleClose = () => {
    setOpenModal(false);
    setSelectedRequest(null);
    setFilePreviews({});
  };

  const handleImageClick = (src) => {
    setImageModalSrc(src);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setImageModalSrc('');
  };

  const handleResubmit = async () => {
    try {
      await API.put(`/request/resubmit/${selectedRequest._id}`);
      setRequests((prevRequests) => 
        prevRequests.map((req) => 
          req._id === selectedRequest._id ? { ...req, status: 'RESUBMITTED' } : req
        )
      );
      setSelectedRequest((prev) => ({ ...prev, status: 'RESUBMITTED' }));
      setOpenModal(false);
      alert('Request resubmitted successfully.');
    } catch (error) {
      console.error('Error resubmitting request:', error);
      alert('Failed to resubmit request.');
    }
  };

  const renderRequestDetails = (request) => {
    if (!request) return null;
  
    const handleDeleteFile = async (field) => {
      const confirmed = window.confirm('Are you sure you want to delete this file?');
      if (confirmed) {
        try {
          await API.delete(`/request/files/${request._id}/${field}`);
          setFilePreviews((prev) => ({ ...prev, [field]: null }));
        } catch (error) {
          console.error(`Error deleting ${field} file:`, error);
          alert('Failed to delete the file.');
        }
      }
    };
  
    const handleFileChange = async (e, field) => {
      const file = e.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append(field, file);
  
        try {
          const response = await API.post(`/request/files/${request._id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });
          const url = URL.createObjectURL(response.data);
          setFilePreviews((prev) => ({ ...prev, [field]: url }));
        } catch (error) {
          console.error(`Error uploading ${field} file:`, error);
          alert('Failed to upload the file.');
        }
      }
    };

    const handleFileUpdate = async (e, field) => {
        const file = e.target.files[0];
        if (!file) return;
      
        const formData = new FormData();
        formData.append('file', file);
      
        try {
          const upload = await API.put(`/request/files/update/${selectedRequest._id}/${field}`, formData);
          const updatedFile = await API.get(`/request/files/${upload.data.fileName}`, {
            responseType: 'blob',
          });
          const url = URL.createObjectURL(updatedFile.data);
          setFilePreviews((prev) => ({ ...prev, [field]: url }));
        } catch (error) {
          console.error(`Error updating ${field}:`, error);
          alert(`Failed to update ${field}.`);
        }
      };
      
  
    const renderFilePreview = (file, field) => {
      const url = filePreviews[field];
      if (!url) return null;
  
      const isPdf = file.endsWith('.pdf');
  
      if (isPdf) {
        return (
          <Box textAlign="center">
            <Box textAlign="center">
            <Typography variant="body2">
              <a href={url} target="_blank" rel="noopener noreferrer">
                {file}
              </a>
            </Typography>
            </Box>
            {request.status === 'MORE_INFO_NEEDED' && ( 
                <Box textAlign="center">
                    <Button
                    variant="contained"
                    component="label"
                    style={{ 
                        marginLeft: '10px', 
                        color: 'white', 
                        padding: '4px 8px', 
                        fontSize: '0.8rem',
                        height: '30px',
                        textAlign: 'center'
                    }}
                    >
                    Update file
                    <input
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        hidden
                        onChange={(e) => handleFileUpdate(e, field)}
                    />
                    </Button>
                    </Box>
                 )}
              </Box>
            );
          }
      
          return (
            <Box textAlign="center">
                <Box textAlign="center">
              <img
                src={url}
                alt={file}
                style={{ width: '100px', height: '100px', cursor: 'pointer' }}
                onClick={() => handleImageClick(url)}
              />
              </Box>
              {request.status === 'MORE_INFO_NEEDED' && ( 
                <Box textAlign="center">
                    <Button
                    variant="contained"
                    component="label"
                    fullWidth
                    style={{  
                        color: 'white', 
                        padding: '4px', 
                        fontSize: '0.8rem',
                        height: '30px',
                        textAlign: 'center'
                    }}
                    >
                    Update file
                    <input
                        type="file"
                        accept=".png,.jpg,.jpeg,.pdf"
                        hidden
                        onChange={(e) => handleFileUpdate(e, field)}
                    />
                    </Button>
                    </Box>
                 )}
            </Box>
          );
        };
      
        return (
          <Box p={4} bgcolor="background.paper" style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}>
            <IconButton onClick={handleClose} style={{ position: 'absolute', top: 8, right: 8 }}>
              <CloseIcon />
            </IconButton>
            {/* Display the "More Info Needed" box if applicable */}
            {request.status === 'MORE_INFO_NEEDED' && (
              <Box mt={2} p={2} bgcolor="orange" borderRadius="8px">
                <Typography variant="h6" gutterBottom>
                  Additional steps are required to process this request
                </Typography>
                <Typography variant="body1"> {request.more_info_reason}</Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="body2"> After you have resolved the issue, resubmit the request</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleResubmit}
                    style={{ marginTop: '10px', textAlign: 'center' }}
                >
                RESUBMIT
                </Button>
              </Box>
            )}
            {request.status === 'DECLINED' && (
              <Box mt={2} p={2} bgcolor="red" borderRadius="8px">
                <Typography variant="h6" gutterBottom>
                  Request has been declined due to:
                </Typography>
                <Typography variant="body1">{request.decline_reason}</Typography>
              </Box>
            )}
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="h6" gutterBottom>
              Personal Details
            </Typography>
            <Typography variant="body1"><strong>Name:</strong> {profileData.first_name} {profileData.last_name}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {profileData.email}</Typography>
            <Typography variant="body1"><strong>Phone:</strong> {profileData.phone}</Typography>
            <Typography variant="body1"><strong>Address:</strong> {profileData.address}, {profileData.city}, {profileData.country}</Typography>
            <Typography variant="body1"><strong>Date of Birth:</strong> {new Date(profileData.date_of_birth).toLocaleDateString()}</Typography>
            <Typography variant="body1"><strong>Citizen of:</strong> {profileData.citizenship}</Typography>
            
            <Typography variant="body1"><strong>Gender:</strong> {profileData.gender}</Typography>
            <Typography variant="body1"><strong>Passport Number:</strong> {profileData.passport_number}</Typography>
            <Typography variant="body1"><strong>Passport Issued in:</strong> {profileData.passport_issued_in}</Typography>
            <Typography variant="body1"><strong>Passport Expires:</strong> {new Date(profileData.passport_expires).toLocaleDateString()}</Typography>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="h6" gutterBottom>
              Request Details
            </Typography>
            <Typography variant="body1"><strong>Type:</strong> {request.type}</Typography>
            <Typography variant="body1"><strong>Address in Serbia:</strong> {request.address_in_serbia}</Typography>
            <Typography variant="body1"><strong>Intended Residence in Serbia:</strong> {new Date(request.intended_residence_in_serbia).toLocaleDateString()}</Typography>
            <Typography variant="body1"><strong>Editorial Office Name:</strong> {request.editorial_office_name}</Typography>
            <Typography variant="body1"><strong>Editorial Office Address:</strong> {request.editorial_office_address}</Typography>
            <Typography variant="body1"><strong>Editorial Office City:</strong> {request.editorial_office_city}</Typography>
            <Typography variant="body1"><strong>Editorial Office Country:</strong> {request.editorial_office_country}</Typography>
            <Typography variant="body1"><strong>Editorial Office Phone:</strong> {request.editorial_office_phone}</Typography>
            <Typography variant="body1"><strong>Foreign Bureau Address in Serbia:</strong> {request.foreign_bureau_address_in_serbia}</Typography>
            <Divider style={{ margin: '20px 0' }} />
            <Typography variant="h6" gutterBottom>
              Files
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box textAlign="center">
                <Typography variant="body1"><strong>Passport or ID</strong></Typography>
                {renderFilePreview(request.passport_photo, 'passport_photo')}
              </Box>
             
              <Box textAlign="center">
                <Typography variant="body1"><strong>Picture</strong></Typography>
                {renderFilePreview(request.personal_photo, 'personal_photo')}
              </Box>
            </Box>
            
            <Box mt={2} textAlign="center">
              <Typography variant="body1"><strong>Employer letter</strong></Typography>
              {renderFilePreview(request.proof_photo, 'proof_photo')}
            </Box>

        
            <Box mt={2} textAlign="center">
              <Typography variant="body1"><strong>Professional CV</strong></Typography>
              {request.professional_cv ? renderFilePreview(request.professional_cv, 'professional_cv') : '/'}
            </Box>
            <Box mt={2} textAlign="center">
              <Typography variant="body1"><strong>Proof of address in RS</strong></Typography>
              {request.proof_of_address_in_rs ? renderFilePreview(request.proof_of_address_in_rs, 'proof_of_address_in_rs') : '/'}
            </Box>

            
          </Box>
        );
      };
        

  return (
    <Box mt={4} mx={2}>
      <Tooltip title={requests && requests.some(req => req.status === 'PENDING' || req.status === 'MORE_INFO_NEEDED') ? 'You already have a processing request. Wait for resolution or delete the existing request.' : ''}>
    <span>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/submit-request')}
        disabled={requests && requests.some(req => req.status === 'PENDING' || req.status === 'MORE_INFO_NEEDED' || req.status === 'RESUBMITTED')}
      >
        Submit a New Request
      </Button>
    </span>
  </Tooltip>
  <Box mt={2} mb={2} display="flex" alignItems="center">
    <TextField
      label="Search Requests"
      variant="outlined"
      fullWidth
      value={searchTerm}
      onChange={handleSearchChange}
    />
    <FormControl variant="outlined" style={{ marginLeft: '16px', minWidth: '200px' }}>
      <InputLabel>Status</InputLabel>
      <Select value={filterStatus} onChange={handleFilterStatusChange} label="Status">
        <MenuItem value="">All</MenuItem>
        <MenuItem value="PENDING">Pending</MenuItem>
        <MenuItem value="APPROVED">Approved</MenuItem>
        <MenuItem value="DECLINED">Declined</MenuItem>
        <MenuItem value="MORE_INFO_NEEDED">More Info Needed</MenuItem>
        <MenuItem value="RESUBMITTED">Resubmitted</MenuItem>
      </Select>
    </FormControl>
  </Box>
  <Paper>
    {requests.length === 0 ? (
      <Typography variant="body1">You have no requests.</Typography>
    ) : (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Submitted</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((request) => (
            <TableRow key={request._id}>
              <TableCell>{request._id}</TableCell>
              <TableCell>{request.type}</TableCell>
              <TableCell>{new Date(request.date_submitted).toLocaleDateString()}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: request.status === 'PENDING' ? 'blue' : request.status === 'APPROVED' ? 'green' : request.status === 'DECLINED' ? 'red' : request.status === 'MORE_INFO_NEEDED' ? 'orange' : request.status === 'EXPIRED' ? 'red' : 'blue',
                    color: 'white',
                  }}
                >
                  {request.status === 'MORE_INFO_NEEDED' ? 'More Info Needed' : request.status}
                </Button>
              </TableCell>
              <TableCell>
                <Tooltip title="View Details">
                  <IconButton onClick={() => handleView(request)}>
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>

                {(request.status !== "APPROVED" && request.status !== "DECLINED" && request.status !== "EXPIRED") && ( 
                    <Tooltip title="Delete Request">
                        <IconButton onClick={() => handleDelete(request._id)}>
                        <DeleteIcon />
                        </IconButton>
                    </Tooltip> 
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )}
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={requests.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
    />
  </Paper>

  <Modal open={openModal} onClose={handleClose}>
    <Box>{renderRequestDetails(selectedRequest)}</Box>
  </Modal>
  <Modal open={imageModalOpen} onClose={handleImageModalClose}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="rgba(0, 0, 0, 0.8)"
      onClick={handleImageModalClose}
    >
      <img src={imageModalSrc} alt="Full size" style={{ maxHeight: '90%', maxWidth: '90%' }} />
    </Box>
  </Modal>
</Box>
);
};

export default UserDashboard;
