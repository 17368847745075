import React, { useContext } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../contexts/AuthContext';
import ConstellationBackground from './ConstellationBackground';

const Documentation = () => {
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === 'ADMIN';

  const userGuide = (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">User Guide</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" paragraph>
          <strong>1. Registration and Login</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Open the registration page and fill in all required information<br />
          • Wait for administrator approval of your account<br />
          • After approval, you can log in with your credentials
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. Submitting Accreditation Request</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Select "Submit Request" option on the dashboard<br />
          • Fill out the form with all necessary information<br />
          • Upload required documentation (passport, proof of employment, personal photo)<br />
          • Submit the request and wait for approval
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>3. Tracking Request Status</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • You can view your request status on the dashboard<br />
          • If additional documentation is needed, you will be notified<br />
          • You will receive email notifications for all status changes<br />
          • After approval, you can access your accreditation from the menu by clicking on 'My Accreditation'<br />
          • You can print or save your accreditation as an image on your device<br />
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>4. Technical Support</strong>
        </Typography>
        <Typography variant="body2">
          • For all technical assistance, use the "Support" option in the menu<br />
          • Fill out the form with a detailed description of the issue
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const adminGuide = (
    <Accordion defaultExpanded={isAdmin}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Упутство за Администратора</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" paragraph>
          <strong>1. Управљање корисницима</strong>
        </Typography>
        <Typography variant="body2" paragraph>
            Као администратор, имате могућност да управљате корисницима и њиховим налозима.
            <br /><br />
          • Прегледајте кориснике<br />
          Кликом на опцију "Корисници" у менију, можете прегледати све регистроване кориснике.<br />
          Ту можете одобрити или одбити регистрацију нових корисника кликом на адекватне опције поред њиховог налога.<br />
          Превлачењем миша преко неке од опција, појавиће се додатна информација о тој опцији. 
          <br /><br />
          • Проверите достављене податке<br />
          Кликом на иконицу ока (Детаљи) у колони Опције, можете прегледати достављене податке о кориснику.
          <br /><br />
          • Суспендујте кориснике<br />
          Кликом на иконицу за Суспензију корисника у колони Опције, можете онемогућити истом даље логовање у систем.
          <br /><br />
          • Уклањање корисничких налога<br />
          Кликом на иконицу за Уклањање корисника у колони Опције, можете трајно уклонити кориснички налог из система.<br />
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. Обрада захтева за акредитацију</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Прегледајте нове захтеве за акредитацију<br />
          Кликом на опцију Захтеви из главног менија, можете прегледати све нове и постојеће захтеве за акредитацију.<br />
          Можете вршити претрагу по имену, презимену, броју документа и статусу захтева.<br />
          <br />
          • Проверите приложену документацију<br />
          Кликом на иконицу за Детаље у колони Опције, можете прегледати сву приложену документацију и сам захтев.<br />
          <br />
          • Затражите додатне информације ако је потребно<br />
          Кликом на иконицу за Затражи информације (писмо) у колони Опције, можете затражити додатне информације од подносиоца.<br />
          У обавези сте да у прозору коју ће се појавити упишете које информације је подносиоц дужан да допуни.<br />
          <br />
          • Одобрите или одбијте захтев<br />
          <br />
          Одобравање<br />
          Кликом на иконицу за Одобри, у колони Опције, одобравате захтев за акредитацију.<br />
          Том приликом у обавези сте да у прозору који ће се појавити, унесете датум до када акредитација важи, као и број еПисарнице.<br />
          <br />
          Одбијање<br />
          Кликом на иконицу за Одбиј, у колони Опције, одбијате захтев за акредитацију.<br />
          Том приликом у обавези сте да у прозору који ће се појавити, унесете разлог за одбијање.<br />
          <br />
          
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const verifierGuide = (
    <Accordion defaultExpanded={isAdmin}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Упутство за верификаторе</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" paragraph>
          <strong>1. Верификација акредитације</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Скенирајте QR код акредитације<br />
          • Потврдите валидност<br />
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. СМС верификација</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Кликните на дугме ”ВЕРИФИКУЈ ПУТЕМ СМС КОДА”<br />
          • На екрану ће се појавити тајни код познат само Вама, а исти ће стићи смс-ом на телефон регистрованог носиоца акредитације.<br />
          • Захтевајте да Вам носилац акредитације прочита код из смс-а који му је стигао и проверите да ли одговара ономе што видите код Вас на екрану..<br />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  const userGuideAdmin = (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6">Упутство за корисника</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" paragraph>
          <strong>1. Регистрација и пријава</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • Отворите страницу за регистрацију и попуните све потребне податке<br />
          • Сачекајте да администратор одобри ваш налог<br />
          • Након одобрења, можете се пријавити са вашим креденцијалима
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>2. Подношење захтева за акредитацију</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • На контролној табли изаберите опцију "Поднеси захтев"<br />
          • Попуните формулар са свим потребним информацијама<br />
          • Приложите потребну документацију (пасош, доказ о запослењу, лична фотографија)<br />
          • Поднесите захтев и сачекајте одобрење
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>3. Праћење статуса захтева</strong>
        </Typography>
        <Typography variant="body2" paragraph>
          • На контролној табли можете видети статус вашег захтева<br />
          • Ако је потребна додатна документација, бићете обавештени<br />
          • Након сваке промене статуса захтева, добићете емаил обавештење.<br />
          • Након одобрења, Вашој акредитацији можете приступити из менија кликом на опцију 'My Accreditation'<br />
          • Акредитацију можете штампати или сачувати као слику на Вашем уређају.<br />
        </Typography>

        <Typography variant="body1" paragraph>
          <strong>4. Техничка подршка</strong>
        </Typography>
        <Typography variant="body2">
          • За сву техничку помоћ, користите опцију "Подршка" у менију<br />
          • Попуните формулар са детаљним описом проблема
        </Typography>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <ConstellationBackground />
      <Box 
        display="flex" 
        flexDirection="column" 
        p={4}
        sx={{ 
          position: 'relative',
          zIndex: 1,
          margin: '20px auto',
          maxWidth: '800px'
        }}
      >
        <Paper 
          elevation={3} 
          sx={{ 
            p: 3, 
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 2
          }}
        >
          <Typography variant="h4" gutterBottom align="center" fontWeight={500} mb={4}>
            
            {(isAdmin) ? "Корисничка упутства" : "Documentation"}
            
          </Typography>
          
          {isAdmin ? (
            <>
              {adminGuide}
              {verifierGuide}
              {userGuideAdmin}
            </>
          ) : (
            userGuide
          )}
          
        </Paper>
      </Box>
    </div>
  );
};

export default Documentation; 