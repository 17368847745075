import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import API from '../services/api';
import AuthLayout from './AuthLayout';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useNavigate } from 'react-router-dom';
import ConstellationBackground from './ConstellationBackground';



const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('forgot_password');
      setRecaptchaToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.post('/auth/forgot-password', { email, recaptchaToken });
      alert('Password reset link sent to your email.');
    } catch (error) {
      console.error(error);
      alert('Error sending reset email.');
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <ConstellationBackground />
    <AuthLayout>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4} width="100%">
        <img src="/logo.png" alt="Foreign Media Accreditation System Logo" style={{ width: '100px', marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom align="center" fontWeight={500}>
          FOMRAS
        </Typography>
        <Typography variant="h6" gutterBottom align="center" fontWeight={100}>
          Foreign Media Representatives Accreditation System
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Enter your email address below and we'll send you a link to reset your password.
        </Typography>
      </Box>
      <Box
      justifyContent="center" 
      style={{ 
          backgroundColor: "white", 
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          borderRadius: "8px", 
          padding: "16px",
          width: "100%",
      }}
      >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
        />
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Send Reset Link
          </Button>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Back to <a href="/login">Login</a>
          </Typography>
        </Box>
      </form>
      </Box>
    </AuthLayout>
    </div>
  );
};

const ForgotPasswordWithReCaptcha = () => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <ForgotPassword />
    </GoogleReCaptchaProvider>
  );
  
  export default ForgotPasswordWithReCaptcha;
  
