import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const AdminDashboard = () => {
  const [userStats, setUserStats] = useState({});
  const [requestStats, setRequestStats] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserStats = async () => {
      try {
        const response = await API.get('/admin/user-stats');
        setUserStats(response.data);
      } catch (error) {
        console.error('Error fetching user stats:', error);
      }
    };

    const fetchRequestStats = async () => {
      try {
        const response = await API.get('/admin/request-stats');
        setRequestStats(response.data);
      } catch (error) {
        console.error('Error fetching request stats:', error);
      }
    };

    fetchUserStats();
    fetchRequestStats();
  }, []);

  const requestData = [
    { name: 'У процесу', value: requestStats.pendingRequests },
    { name: 'Одобрено', value: requestStats.approvedRequests },
    { name: 'Одбијено', value: requestStats.declinedRequests },
    { name: 'Истекло', value: requestStats.expiredRequests },
    { name: 'Потребне додатне информације', value: requestStats.moreInfoRequests },
    { name: 'Допуњено', value: requestStats.resubmittedRequests },
  ];

  const userData = [
    { name: 'Чека активацију', value: userStats.newUsers },
    { name: 'Активни', value: userStats.activeUsers },
    { name: 'Суспендовани', value: userStats.suspendedUsers },
  ];

  const COLORS = ['#0088FE', '#FFBB28', '#00C49F', '#FF8042', '#FF4040', '#40FF40'];

  return (
    <Box mt={4} mx={2}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Корисници</Typography>
              <Typography variant="body1">Укупно: {userStats.totalUsers}</Typography>
              <Typography variant="body1">Чека активацију: {userStats.newUsers}</Typography>
              <Typography variant="body1">Активни: {userStats.activeUsers}</Typography>
              <Typography variant="body1">Суспендовани: {userStats.suspendedUsers}</Typography>
              <Box mt={2}>
                <PieChart width={300} height={300}>
                  <Pie
                    data={userData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {userData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={() => navigate('/admin/users')}>
                    Преглед корисника
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6">Захтеви</Typography>
              <Typography variant="body1">Укупно: {requestStats.totalRequests}</Typography>
              <Typography variant="body1">У процесу: {requestStats.pendingRequests}</Typography>
              <Typography variant="body1">Одобрено: {requestStats.approvedRequests}</Typography>
              <Typography variant="body1">Одбијено: {requestStats.declinedRequests}</Typography>
              <Box mt={2}>
                <PieChart width={300} height={300}>
                  <Pie
                    data={requestData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {requestData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
                <Box mt={2}>
                  <Button variant="contained" color="primary" onClick={() => navigate('/admin/requests')}>
                    Преглед Захтева
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
