import React, { useState, useEffect, useContext } from 'react';
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import API from '../services/api';
import AuthLayout from './AuthLayout';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { AuthContext } from '../contexts/AuthContext';
import ConstellationBackground from './ConstellationBackground';
import shadows from '@mui/material/styles/shadows';


const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { login } = useContext(AuthContext);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const recaptchaToken = await executeRecaptcha('login');
      const response = await API.post('/auth/login', { ...formData, recaptchaToken });
      const { token } = response.data;
      
      if (token) {
        login(token); 
        navigate('/dashboard');
        window.location.reload();
      } else {
        alert('Login failed');
      }
    } catch (error) {
      console.error(error);
      alert('Login failed.');
    }
  };

  return (
    <div style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}>
      <ConstellationBackground /> {/* Background animation */}
    <AuthLayout>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4} width="100%">
        <img src="/logo.png" alt="Foreign Media Accreditation System Logo" style={{ width: '100px', marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom align="center" fontWeight={500}>
          FOMRAS
        </Typography>
        <Typography variant="h6" gutterBottom align="center" fontWeight={100}>
          Foreign Media Representatives Accreditation System
        </Typography>
      </Box>
      <Box 
        display="flex" 
        justifyContent="center" 
        mb={2} 
        style={{ 
            backgroundColor: "white", 
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
            borderRadius: "8px", 
            padding: "16px" 
        }}
        >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label="Password"
          variant="outlined"
          fullWidth
          margin="normal"
          name="password"
          type="password"
          value={formData.password}
          onChange={handleChange}
        />
        
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </Box>
        <Box mt={2} textAlign="center">
          <Button href="/forgot-password" variant="text">
            Forgot password?
          </Button>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Don't have an account? <Link href="/register">Register here</Link>
          </Typography>
        </Box>
      </form>
      </Box>
    </AuthLayout>
    </div>
  );
};

  
export default Login;
