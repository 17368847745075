import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  TextField, 
  Typography, 
  Card, 
  CardContent, 
  CardHeader, 
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import API from '../services/api';
import CountrySelect from './CountrySelect';
import PhoneInputField from './PhoneInputField';
import Select from 'react-select';

const professionalStatusOptions = [
    { value: 'reporter-journalist', label: 'Reporter/Journalist' },
    { value: 'correspondent', label: 'Correspondent' },
    { value: 'editor', label: 'Editor' },
    { value: 'photojournalist', label: 'Photojournalist' },
    { value: 'videographer', label: 'Videographer' },
    { value: 'columnist', label: 'Columnist' },
    { value: 'blogger', label: 'Blogger' },
    { value: 'producer', label: 'Producer' },
    { value: 'other', label: 'Other' },
];


const Profile = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    date_of_birth: '',
    citizenship: '',
    professional_status: '',
    passport_number: '',
    passport_issued_in: '',
    passport_expires: '',
    gender: '',
  });

  useEffect(() => {
    if (isAuthenticated) {
      // Fetch user profile data
      const fetchProfileData = async () => {
        try {
          const response = await API.get('/user/profile');
          const data = response.data;

          setProfileData({
            email: data.email || '',
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            phone: data.phone || '',
            address: data.address || '',
            city: data.city || '',
            country: data.country || '',
            date_of_birth: data.date_of_birth ? data.date_of_birth.split('T')[0] : '', // Assuming date is in ISO format
            citizenship: data.citizenship || '',
            professional_status: data.professional_status || '',
            passport_number: data.passport_number || '',
            passport_issued_in: data.passport_issued_in || '',
            passport_expires: data.passport_expires ? data.passport_expires.split('T')[0] : '', // Assuming date is in ISO format
            gender: data.gender || '',
          });
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      };

      fetchProfileData();
    } else {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setProfileData({ ...profileData, phone: value });
  };

  const handleCountryChange = (fieldName, value) => {
    setProfileData({ ...profileData, [fieldName]: value });
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setProfileData({ ...profileData, [fieldName]: selectedOption ? selectedOption.value : '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await API.put('/user/profile', profileData);
      alert('Profile updated successfully.');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Failed to update profile.');
    }
  };

  return (
    <Box mt={4} mx={2} display="flex" justifyContent="center">
      <Card style={{ width: '100%', maxWidth: '800px' }}>
        <CardHeader title="My Profile" />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={profileData.email}
                onChange={handleChange}
                required
              />
            </Box>
            <Divider style={{ margin: '20px 0' }} />
            <Box mb={2}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                name="first_name"
                value={profileData.first_name}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                name="last_name"
                value={profileData.last_name}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={2}>
              <PhoneInputField
                value={profileData.phone}
                onChange={handlePhoneChange}
                placeholder="Phone"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                name="address"
                value={profileData.address}
                onChange={handleChange}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                name="city"
                value={profileData.city}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={2}>
              <CountrySelect
                value={profileData.country}
                onChange={(value) => handleCountryChange('country', value)}
                placeholder="Select Country"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Date of Birth"
                variant="outlined"
                fullWidth
                name="date_of_birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={profileData.date_of_birth}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={2}>
              <CountrySelect
                value={profileData.citizenship}
                onChange={(value) => handleCountryChange('citizenship', value)}
                placeholder="Select Citizenship"
              />
            </Box>
            <Box mb={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup row aria-label="gender" name="gender" value={profileData.gender} onChange={handleChange}>
                  <FormControlLabel value="m" control={<Radio />} label="Male" />
                  <FormControlLabel value="f" control={<Radio />} label="Female" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider style={{ margin: '20px 0' }} />
            <Box mb={2}>
              <Typography variant="h6" gutterBottom>Passport Information</Typography>
              <CountrySelect
                value={profileData.passport_issued_in}
                onChange={(value) => handleCountryChange('passport_issued_in', value)}
                placeholder="Select Passport Issued In"
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Passport Number"
                variant="outlined"
                fullWidth
                name="passport_number"
                value={profileData.passport_number}
                onChange={handleChange}
                required
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Passport Expires"
                variant="outlined"
                fullWidth
                name="passport_expires"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={profileData.passport_expires}
                onChange={handleChange}
                required
              />
            </Box>
           
            
            <Box mb={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Professional Status</FormLabel>
                <Select
                  options={professionalStatusOptions}
                  value={professionalStatusOptions.find(option => option.value === profileData.professional_status) || null}
                  onChange={(option) => handleSelectChange(option, 'professional_status')}
                  placeholder="Select Professional Status"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: 'white',
                      border: '1px solid #ced4da',
                      borderRadius: '4px',
                      minHeight: '56px',
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: 'white',
                      zIndex: 9999,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? '#007bff' : 'white',
                      color: state.isSelected ? 'white' : 'black',
                      padding: 10,
                    }),
                  }}
                  isClearable
                  required
                />
              </FormControl>
            </Box>
            
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Update
              </Button>
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Profile;
