import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Logout from './components/Logout';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import SubmitRequest from './components/SubmitRequest';
import Profile from './components/Profile';
import Accreditation from './components/Accreditation';
import PrivateRoute from './components/PrivateRoute';
import { isAuthenticated, getRoleFromToken } from './utils/auth';
import { AuthProvider } from './contexts/AuthContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Header from './components/Header';
import Users from './components/Users';
import Requests from './components/Requests';
import QRValidationPage from './components/QRValidationPage';
import Support from './components/Support';
import Documentation from './components/Documentation';
import Footer from './components/Footer';


const App = () => {
  const [role, setRole] = useState('');

  useEffect(() => {
    if (isAuthenticated()) {
      const userRole = getRoleFromToken();
      setRole(userRole);
    }
  }, []);

  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <AuthProvider>
      <Router>
      <div style={{ paddingBottom: '60px' }}> 
        {isAuthenticated() && <Header role={role} />}
        <div style={{ flex: 1, padding: '20px' }}>
          <Routes>
            {/* Public Route without auth */}
            <Route path="/verify/:requestId" element={<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}><QRValidationPage /></GoogleReCaptchaProvider>} />

            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/login" element={
              isAuthenticated() ? <Navigate to="/dashboard" /> :
              <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}><Login /></GoogleReCaptchaProvider>
            } />
            <Route path="/submit-request" element={<PrivateRoute><SubmitRequest /></PrivateRoute>} />
            <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
            <Route path="/my-id" element={<PrivateRoute><Accreditation /></PrivateRoute>} />

            <Route path="/admin/users" element={<PrivateRoute><Users /></PrivateRoute>} />
            <Route path="/admin/requests" element={<PrivateRoute><Requests /></PrivateRoute>} />

            <Route path="/register" element={<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}><Register /></GoogleReCaptchaProvider>} />
            <Route path="/forgot-password" element={<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}><ForgotPassword /></GoogleReCaptchaProvider>} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/reset-password/:token" element={<GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}><ResetPassword /></GoogleReCaptchaProvider>} />
            <Route path="/support" element={<Support />} />
            <Route path="/documentation" element={<Documentation />} />
          </Routes>
          <Footer />
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
  
};

export default App;
