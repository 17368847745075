import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  IconButton,
  TextField,
  Link,
} from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import API from '../services/api';
import { AuthContext } from '../contexts/AuthContext';
import CountrySelect from './CountrySelect';
import PhoneInputField from './PhoneInputField';
import Resizer from 'react-image-file-resizer';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SubmitRequest = () => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [activeRequest, setActiveRequest] = useState(null);
  const [formData, setFormData] = useState({
    type: '', // Updated requestType to type
    address_in_serbia: '',
    intended_residence_in_serbia: '',
    editorial_office_name: '',
    editorial_office_address: '',
    editorial_office_city: '',
    editorial_office_country: '',
    editorial_office_phone: '',
    foreign_bureau_address_in_serbia: '',
    passport_photo: null,
    personal_photo: null,
    proof_photo: null,
    professional_cv: null,
    proof_of_address_in_rs: null,

  });
  const [filePreviews, setFilePreviews] = useState({
    passport_photo: null,
    personal_photo: null,
    proof_photo: null,
    professional_cv: null,
    proof_of_address_in_rs: null,
  });
  const [fileUploadStatus, setFileUploadStatus] = useState({
    passport_photo: false,
    personal_photo: false,
    proof_photo: false,
    professional_cv: null,
    proof_of_address_in_rs: null,
  });

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      const fetchProfileData = async () => {
        try {
          const profileResponse = await API.get('/user/profile');
          setProfileData(profileResponse.data);
        } catch (error) {
          console.error('Error fetching profile data:', error);
        }
      };

      const fetchActiveRequest = async () => {
        try {
          const requestResponse = await API.get('/user/requests?status=APPROVED');
          if(requestResponse.data.length > 0) {
            setActiveRequest(requestResponse.data);
          }
        } catch (error) {
          console.error('Error fetching active request:', error);
        }
      };

      fetchProfileData();
      fetchActiveRequest();
    } else {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleFileUpload = (acceptedFiles, fieldName) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log('File type:', file.type);
      setFileUploadStatus((prevStatus) => ({ ...prevStatus, [fieldName]: true }));
      setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: file }));

      const previewData = {
        url: URL.createObjectURL(file),
        type: file.type,
        name: file.name
      };
      console.log('Setting preview data:', previewData); // Debug log
    
      
      setFilePreviews((prevPreviews) => ({ ...prevPreviews, [fieldName]: previewData }));
      setFileUploadStatus((prevStatus) => ({ ...prevStatus, [fieldName]: false }));
    }
  };
  

  const handleFileDelete = (fieldName) => {
    setFormData((prevFormData) => ({ ...prevFormData, [fieldName]: null }));
    setFilePreviews((prevPreviews) => ({ ...prevPreviews, [fieldName]: null }));
    if (filePreviews[fieldName]?.url) {
      URL.revokeObjectURL(filePreviews[fieldName].url);
    }
  };

  const { getRootProps: getPassportRootProps, getInputProps: getPassportInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, 'passport_photo'),
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    disabled: !!filePreviews.passport_photo,
  });

  const { getRootProps: getProofRootProps, getInputProps: getProofInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, 'proof_photo'),
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    disabled: !!filePreviews.proof_photo,
  });

  const { getRootProps: getPersonalRootProps, getInputProps: getPersonalInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, 'personal_photo'),
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxFiles: 1,
    disabled: !!filePreviews.personal_photo,
  });

  const { getRootProps: getProfessionalCvRootProps, getInputProps: getProfessionalCvInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, 'professional_cv'),
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    disabled: !!filePreviews.professional_cv,
  });

  const { getRootProps: getProofOfAddressInRsRootProps, getInputProps: getProofOfAddressInRsInputProps } = useDropzone({
    onDrop: (acceptedFiles) => handleFileUpload(acceptedFiles, 'proof_of_address_in_rs'),
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    disabled: !!filePreviews.proof_of_address_in_rs,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.passport_photo) newErrors.passport_photo = 'Passport or ID photo is required';
    if (!formData.personal_photo) newErrors.personal_photo = 'Personal photo is required';
    if (!formData.proof_photo) newErrors.proof_photo = 'Employer letter is required';
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) {
      return;
    }
  
    if (activeRequest) {
      alert('You already have an active request.');
      return;
    }
  
    try {
      const data = new FormData();
      for (const key in formData) {
        if (formData[key]) {
          data.append(key, formData[key]);
        }
      }
  
      await API.post('/request/submit', data);
      alert('Request submitted successfully.');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error submitting request:', error);
      alert('Failed to submit request.');
    }
  };
  

  const today = new Date().toISOString().split('T')[0];

  return (
    <Box mt={4} mx={2} display="flex" justifyContent="center">
      <Card style={{ width: '100%', maxWidth: '800px' }}>
        <CardHeader title="Submit a Request" />
        <CardContent>
          <Box mb={2}>
            <Typography variant="h6" gutterBottom>Personal Information</Typography>
            <Typography variant="body1"><strong>Name:</strong> {profileData.first_name} {profileData.last_name}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {profileData.email}</Typography>
            <Typography variant="body1"><strong>Phone:</strong> {profileData.phone}</Typography>
            <Typography variant="body1"><strong>Address:</strong> {profileData.address}, {profileData.city}, {profileData.country}</Typography>
            <Typography variant="body1"><strong>Date of Birth:</strong> {new Date(profileData.date_of_birth).toLocaleDateString()}</Typography>
            <Typography variant="body1"><strong>Citizen of:</strong> {profileData.citizenship}</Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="body2">
              <InfoOutlinedIcon style={{ marginRight: 6, fontSize: 16 }} />
              Did something change? Update your personal details <Link component={RouterLink} to="/profile">here</Link>
            </Typography>
          </Box>
          <Divider style={{ margin: '20px 0' }} />
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Request Type</FormLabel>
                <RadioGroup row aria-label="type" name="type" value={formData.type} onChange={(e) => setFormData({ ...formData, type: e.target.value })}>
                  <FormControlLabel value="representative" control={<Radio />} label="Register as a Representative" />
                  <FormControlLabel value="bureau" control={<Radio />} label="Register a Foreign Bureau" />
                </RadioGroup>
              </FormControl>
            </Box>
            {formData.type === 'representative' && (
              <>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h6" gutterBottom>Additional Information</Typography>
                <Box mb={2}>
                  <TextField
                    label="Address of residence in Serbia"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="address_in_serbia"
                    value={formData.address_in_serbia}
                    onChange={(e) => setFormData({ ...formData, address_in_serbia: e.target.value })}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Intended Residence in Serbia"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="intended_residence_in_serbia"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formData.intended_residence_in_serbia}
                    onChange={(e) => setFormData({ ...formData, intended_residence_in_serbia: e.target.value })}
                    required
                    inputProps={{ min: today }}
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Editorial Office Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="editorial_office_name"
                    value={formData.editorial_office_name}
                    onChange={(e) => setFormData({ ...formData, editorial_office_name: e.target.value })}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Editorial Office Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="editorial_office_address"
                    value={formData.editorial_office_address}
                    onChange={(e) => setFormData({ ...formData, editorial_office_address: e.target.value })}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Editorial Office City"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="editorial_office_city"
                    value={formData.editorial_office_city}
                    onChange={(e) => setFormData({ ...formData, editorial_office_city: e.target.value })}
                    required
                  />
                </Box>
                <Box mb={2}>
                  <CountrySelect
                    label="Editorial Office Country"
                    value={formData.editorial_office_country}
                    onChange={(value) => setFormData({ ...formData, editorial_office_country: value })}
                    placeholder="Editorial Office Country *"
                    required
                  />
                </Box>
                <Box mb={2}>
                  <PhoneInputField
                    label="Editorial Office Phone"
                    value={formData.editorial_office_phone}
                    onChange={(value) => setFormData({ ...formData, editorial_office_phone: value })}
                    placeholder="Editorial Office Phone *"
                    required
                  />
                </Box>
                <Box mb={2}>
                  <TextField
                    label="Foreign Bureau Address in Serbia"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="foreign_bureau_address_in_serbia"
                    value={formData.foreign_bureau_address_in_serbia}
                    onChange={(e) => setFormData({ ...formData, foreign_bureau_address_in_serbia: e.target.value })}
                  />
                </Box>
                <Box mb={2}>
                    <Typography variant="body1">Passport or ID *</Typography>
                    <div {...getPassportRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center', cursor: 'pointer' }}>
                        <input {...getPassportInputProps()} />
                        {fileUploadStatus.passport_photo ? (
                        <CircularProgress />
                        ) : (
                        <p>Select file</p>
                        )}
                    </div>
                    {errors.passport_photo && <Typography color="error">{errors.passport_photo}</Typography>}
                    {filePreviews.passport_photo && (
                        <Box display="flex" alignItems="center" mt={2}>
                        {filePreviews.passport_photo?.type === 'application/pdf' ? (
                            <Typography variant="body2">{filePreviews.passport_photo.name}</Typography>
                        ) : (
                            <img src={filePreviews.passport_photo.url} alt="Passport Preview" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                        )}
                        <IconButton onClick={() => handleFileDelete('passport_photo')}>
                            <DeleteIcon />
                        </IconButton>
                        </Box>
                    )}
                </Box>
                
                <Box mb={2}>
                  <Typography variant="body1">Employer letter <small>(office ID, letter, etc.)</small> *</Typography>
                  <div {...getProofRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getProofInputProps()} />
                    {fileUploadStatus.proof_photo ? (
                      <CircularProgress />
                    ) : (
                      <p>Select file</p>
                    )}
                  </div>
                  {errors.proof_photo && <Typography color="error">{errors.proof_photo}</Typography>}
                  {filePreviews.proof_photo && (
                    <Box display="flex" alignItems="center" mt={2}>
                      {filePreviews.proof_photo?.type === 'application/pdf' ? (
                        <Typography variant="body2">{filePreviews.proof_photo.name}</Typography>
                      ) : (
                        <img src={filePreviews.proof_photo.url} alt="Proof Preview" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                      )}
                      <IconButton onClick={() => handleFileDelete('proof_photo')}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>

                <Box mb={2}>
                  <Typography variant="body1">Professional CV </Typography>
                  <div {...getProfessionalCvRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getProfessionalCvInputProps()} />
                    {fileUploadStatus.professional_cv ? (
                      <CircularProgress />
                    ) : (
                      <p>Select file</p>
                    )}
                  </div>
                  {errors.professional_cv && <Typography color="error">{errors.professional_cv}</Typography>}
                  {filePreviews.professional_cv && (
                    <Box display="flex" alignItems="center" mt={2}>
                      {filePreviews.professional_cv?.type === 'application/pdf' ? (
                        <Typography variant="body2">{filePreviews.professional_cv.name}</Typography>
                      ) : (
                        <img src={filePreviews.professional_cv.url} alt="CV Preview" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                      )}
                      <IconButton onClick={() => handleFileDelete('professional_cv')}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box mb={2}>
                  <Typography variant="body1">Proof of address in RS</Typography>
                  <div {...getProofOfAddressInRsRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getProofOfAddressInRsInputProps()} />
                    {fileUploadStatus.proof_of_address_in_rs ? (
                      <CircularProgress />
                    ) : (
                      <p>Select file</p>
                    )}
                  </div>
                  {errors.proof_of_address_in_rs && <Typography color="error">{errors.proof_of_address_in_rs}</Typography>}
                  {filePreviews.proof_of_address_in_rs && (
                    <Box display="flex" alignItems="center" mt={2}>
                      {filePreviews.proof_of_address_in_rs?.type === 'application/pdf' ? (
                        <Typography variant="body2">{filePreviews.proof_of_address_in_rs.name}</Typography>
                      ) : (
                        <img src={filePreviews.proof_of_address_in_rs.url} alt="CV Preview" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                      )}
                      <IconButton onClick={() => handleFileDelete('proof_of_address_in_rs')}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>


                <Box mb={2}>
                  <Typography variant="body1">Personal Photo *</Typography>
                  <div {...getPersonalRootProps()} style={{ border: '1px dashed #ccc', padding: '10px', textAlign: 'center', cursor: 'pointer' }}>
                    <input {...getPersonalInputProps()} />
                    {fileUploadStatus.personal_photo ? (
                      <CircularProgress />
                    ) : (
                      <p>Select file</p>
                    )}
                  </div>
                  {errors.personal_photo && <Typography color="error">{errors.personal_photo}</Typography>}
                  {filePreviews.personal_photo && (
                    <Box display="flex" alignItems="center" mt={2}>
                      {filePreviews.personal_photo?.type === 'application/pdf' ? (
                        <Typography variant="body2">{filePreviews.personal_photo.name}</Typography>
                      ) : (
                        <img src={filePreviews.personal_photo.url} alt="Personal Preview" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                      )}
                      <IconButton onClick={() => handleFileDelete('personal_photo')}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Box>
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary" fullWidth>
                    Submit Request
                  </Button>
                </Box>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SubmitRequest;
