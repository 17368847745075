import React, { useEffect, useContext } from 'react';
import { Box, Typography } from '@mui/material';
import ConstellationBackground from './ConstellationBackground';
import { AuthContext } from '../contexts/AuthContext';

const Support = () => {
  const { user } = useContext(AuthContext);
  const isAdmin = user?.role === 'ADMIN';

  useEffect(() => {
    const script = document.createElement('script');
    script.id = "68ad17af-597a-4bbe-8f30-dd698f921cd5";
    script.src = "https://techelement.youtrack.cloud/static/simplified/form/form-entry.js";
    script.setAttribute('data-yt-url', 'https://techelement.youtrack.cloud');
    script.setAttribute('data-theme', 'light');
    script.setAttribute('data-lang', 'en');
    
    let container = document.getElementById('support-form-container');
    if (container) {
      container.appendChild(script);
    }

    return () => {
      if (container && script) {
        container.removeChild(script);
      }
      const forms = document.querySelectorAll('.youtrack-form');
      forms.forEach(form => form.remove());
    };
  }, []);

  return (
    <div style={{ position: 'relative', minHeight: '100vh', overflow: 'hidden' }}>
      <ConstellationBackground />
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        p={4}
        sx={{ 
          position: 'relative',
          zIndex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: 2,
          margin: '20px auto',
          maxWidth: '800px'
        }}
      >
        <Typography variant="h4" gutterBottom align="center" fontWeight={500}>
          {isAdmin ? 'Техничка подршка' : 'Support'}
        </Typography>
        <Typography variant="body1" gutterBottom align="center" mb={4}>
          {isAdmin 
            ? 'Поднесите тикет техничкој подршци.'
            : 'Please fill out the form below to contact technical support.<br/>If you have non-technical questions, please contact us at danja.mataruga@mit.gov.rs'}
        </Typography>
        <div id="support-form-container"></div>
      </Box>
    </div>
  );
};

export default Support; 