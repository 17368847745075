import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputField = ({ value, onChange, placeholder }) => {
  return (
    <PhoneInput
      country={'rs'}
      value={value}
      onChange={onChange}
      inputStyle={{ width: '100%', height: '56px', fontSize: '16px' }}
      placeholder={placeholder}
    />
  );
};

export default PhoneInputField;
