import { jwtDecode } from 'jwt-decode';

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (token) {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getRoleFromToken = () => {
  const token = getToken();
  if (token) {
    const decoded = jwtDecode(token);
    return decoded.role;
  }
  return null;
};
