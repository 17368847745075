import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Modal,
  Typography,
  Divider,
  CircularProgress
} from '@mui/material';
import API from '../services/api';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterStatus, setFilterStatus] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, [page, rowsPerPage, searchTerm, filterStatus]);

  const fetchUsers = async () => {
    try {
      const response = await API.get('/admin/users', {
        params: {
          page: page + 1, // Backend page numbers usually start from 1
          limit: rowsPerPage,
          searchTerm,
          filterStatus
        }
      });
      setUsers(response.data.users);
      setTotalUsers(response.data.total);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(0); // Reset to first page on search term change
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0); // Reset to first page on rows per page change
  };

  const handleFilterStatusChange = (event) => {
    setFilterStatus(event.target.value);
    setPage(0); // Reset to first page on filter change
  };

  const handleViewUser = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUser(null);
  };

  const handleAction = async (user, action) => {
    if (!user || !action) return;

    setIsLoading(true);

    try {
      if (action === 'verify') {
        await API.put(`/admin/users/${user._id}/verify`);
      } else if (action === 'suspend') {
        const confirmed = window.confirm('Are you sure you want to suspend this user?');
        if (confirmed) {
          await API.put(`/admin/users/${user._id}/suspend`);
        }
      } else if (action === 'activate') {
        await API.put(`/admin/users/${user._id}/activate`);
      } else if (action === 'delete') {
        const confirmed = window.confirm('Are you sure you want to delete this user?');
        if (confirmed) {
          await API.delete(`/admin/users/${user._id}`);
        }
      }
      await fetchUsers();
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box mt={4} mx={2}>
      <Card>
        <CardHeader title="Корисници" />
        <CardContent>
          <Box mb={2} display="flex">
            <TextField
              label="Претрага"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              halfwidth="true"
            />
            <FormControl variant="outlined" style={{ marginLeft: '16px', width: '100px' }}>
              <InputLabel>Статус</InputLabel>
              <Select
                value={filterStatus}
                onChange={handleFilterStatusChange}
                label="Status"
              >
                <MenuItem value="">Сви</MenuItem>
                <MenuItem value="ACTIVE">Активни</MenuItem>
                <MenuItem value="PENDING">Неверификовани</MenuItem>
                <MenuItem value="SUSPENDED">Суспендовани</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Име</TableCell>
                  <TableCell>Е-маил</TableCell>
                  <TableCell>Телефон</TableCell>
                  <TableCell>Статус налога</TableCell>
                  <TableCell>Стање налога</TableCell>
                  <TableCell>Опције</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user.first_name} {user.last_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.phone}</TableCell>
                    {user.status === "ACTIVE" ? (
                      <TableCell>Активан</TableCell>
                    ) : (
                      <TableCell>Суспендован</TableCell>
                    )}

                    {user.isVerified === true ? (
                      <TableCell>Верификован</TableCell>
                    ) : (
                      <TableCell>Није верификован</TableCell>
                    )}

                    <TableCell>
                      <Tooltip title="Детаљи">
                        <IconButton onClick={() => handleViewUser(user)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                      {user.isVerified === false && (
                        <Tooltip title="Верификуј корисника">
                          <IconButton onClick={() => handleAction(user, 'verify')}>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {user.status === 'ACTIVE' ? (
                        <Tooltip title="Суспендуј корисника">
                          <IconButton onClick={() => handleAction(user, 'suspend')}>
                            <BlockIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Активирај корисника">
                          <IconButton onClick={() => handleAction(user, 'activate')}>
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Уклони корисника">
                        <IconButton onClick={() => handleAction(user, 'delete')}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalUsers}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Paper>
        </CardContent>
      </Card>
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          p={4}
          bgcolor="background.paper"
          style={{ maxWidth: '600px', margin: '0 auto', position: 'relative', overflowY: 'auto', maxHeight: '80vh' }}
        >
          <IconButton
            onClick={handleCloseModal}
            style={{ position: 'absolute', top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedUser && (
            <>
              <Typography variant="h6" gutterBottom>
                Подаци о кориснику
              </Typography>
              <Typography variant="body1"><strong>Име:</strong> {selectedUser.first_name} {selectedUser.last_name}</Typography>
              <Typography variant="body1"><strong>Е-маил:</strong> {selectedUser.email}</Typography>
              <Typography variant="body1"><strong>Телефон:</strong> {selectedUser.phone}</Typography>
              <Typography variant="body1"><strong>Адреса:</strong> {selectedUser.address}, {selectedUser.city}, {selectedUser.country}</Typography>
              <Typography variant="body1"><strong>Датум рођења:</strong> {new Date(selectedUser.date_of_birth).toLocaleDateString()}</Typography>
              <Typography variant="body1"><strong>Држављанство:</strong> {selectedUser.citizenship}</Typography>
              <Typography variant="body1"><strong>Пол:</strong> {selectedUser.gender === 'm' ? 'Мушки' : 'Женски'}</Typography>
              <Divider style={{ margin: '20px 0' }} />
              <Typography variant="body1"><strong>Професионални статус:</strong> {selectedUser.professional_status}</Typography>
              <Typography variant="body1"><strong>Број пасоша:</strong> {selectedUser.passport_number}</Typography>
              <Typography variant="body1"><strong>Пасош издат у:</strong> {selectedUser.passport_issued_in}</Typography>
              <Typography variant="body1"><strong>Пасош истиче:</strong> {new Date(selectedUser.passport_expires).toLocaleDateString()}</Typography>
              <Divider style={{ margin: '20px 0' }} />
              <Typography variant="body1"><strong>Статус налога:</strong> {selectedUser.status === 'ACTIVE' ? 'Активан' : 'Суспендован'}</Typography>
              <Typography variant="body1"><strong>Стање налога:</strong> {selectedUser.isVerified ? 'Верификован' : 'Није верификован'}</Typography>
            </>
          )}
        </Box>
      </Modal>
      {isLoading && (
        <Modal open={isLoading}>
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Users;
