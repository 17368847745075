import React, { useState, useEffect } from 'react';
import { 
    TextField, 
    Button, 
    Box, 
    Typography, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    FormControl, 
    FormLabel, 
    LinearProgress, 
    Tooltip,
    Divider,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import API from '../services/api';
import AuthLayout from './AuthLayout';
import { useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import ConstellationBackground from './ConstellationBackground';

const professionalStatusOptions = [
    { value: 'reporter-journalist', label: 'Reporter/Journalist' },
    { value: 'correspondent', label: 'Correspondent' },
    { value: 'editor', label: 'Editor' },
    { value: 'photojournalist', label: 'Photojournalist' },
    { value: 'videographer', label: 'Videographer' },
    { value: 'columnist', label: 'Columnist' },
    { value: 'blogger', label: 'Blogger' },
    { value: 'producer', label: 'Producer' },
    { value: 'other', label: 'Other' },
  ];

// Custom option component to include flags
const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  const flagUrl = `https://flagcdn.com/w20/${data.value.toLowerCase()}.png`; // Use flag CDN

  return (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      <img src={flagUrl} alt={data.label} style={{ marginRight: 10, width: 20 }} />
      {data.label}
    </div>
  );
};

const Register = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    first_name: '',
    last_name: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    date_of_birth: '',
    citizenship: '',
    professional_status: '',
    passport_number: '',
    passport_issued_in: '',
    passport_expires: '',
    gender: 'm', // Default to Male
  });
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [countryOptions, setCountryOptions] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
      }
      const token = await executeRecaptcha('register');
      setRecaptchaToken(token);
    };
  
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  useEffect(() => {
    const options = countryList().getData();
    setCountryOptions(options);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'email') {
        validateEmail(value);
      }

    if (name === 'password') {
        evaluatePasswordStrength(value);
        setPasswordError(value.length < 8 ? 'Password must be at least 8 characters long.' : ''); // Check length
      }


    if (name === 'confirmPassword') {
      setPasswordMatch(value === formData.password);
    }
  };

  const validateEmail = (email) => {
    if (email === '') {
      setEmailError('');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Invalid email address.');
    } else {
      setEmailError('');
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleCountryChange = (selectedOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedOption ? selectedOption.label : '' });
  };

  const handleSelectChange = (selectedOption, fieldName) => {
    setFormData({ ...formData, [fieldName]: selectedOption ? selectedOption.value : '' });
  };

  const evaluatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength += 1;
    if (/[A-Z]/.test(password)) strength += 1;
    if (/[0-9]/.test(password)) strength += 1;
    if (/[^A-Za-z0-9]/.test(password)) strength += 1;
    setPasswordStrength(strength);
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'yellow';
      case 4:
        return 'green';
      default:
        return 'red';
    }
  };

  const getPasswordStrengthText = () => {
    switch (passwordStrength) {
      case 1:
        return 'Weak password';
      case 2:
        return 'Average';
      case 3:
        return 'Strong';
      case 4:
        return 'Very Strong';
      default:
        return 'Weak password';
    }
  };

  const verifyCallback = async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('register');
    setRecaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password.length < 8) {
        setPasswordError('Password must be at least 8 characters long.');
        return;
      }
    if (formData.password !== formData.confirmPassword) {
      alert('Passwords do not match.');
      return;
    }
    if (emailError) {
        alert('Please enter a valid email address.');
        return;
      }

      if (!recaptchaToken) {
        alert('Please verify that you are not a robot.');
        return;
      }

    try {
      await API.post('/auth/register', { ...formData, recaptchaToken });
      alert('Registration successful. Please log in.');
      navigate('/login');
    } catch (error) {
      console.error(error);
      alert('Registration failed.');
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];
  // Calculate the maximum date for the date of birth field to ensure the user is at least 18 years old
  const maxDateOfBirth = new Date();
  maxDateOfBirth.setFullYear(maxDateOfBirth.getFullYear() - 18);
  const maxDateOfBirthString = maxDateOfBirth.toISOString().split('T')[0];

  return (
    <AuthLayout>
      <Box display="flex" flexDirection="column" alignItems="center" mb={4} width="100%">
        <img src="/logo.png" alt="Foreign Media Accreditation System Logo" style={{ width: '100px', marginBottom: '20px' }} />
        <Typography variant="h4" gutterBottom align="center" fontWeight={500}>
          FOMRAS
        </Typography>
        <Typography variant="h6" gutterBottom align="center" fontWeight={100}>
          Foreign Media Representatives Accreditation System
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Please fill in the details to create an account.
          <br/>
          Fields marked with * are required. 
        </Typography>
      </Box>
      <Box
      justifyContent="center" 
      style={{ 
          backgroundColor: "white", 
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", 
          borderRadius: "8px", 
          padding: "16px",
          width: "100%",
      }}
      >
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>1. Personal Information</Typography>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="first_name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            margin="normal"
            name="last_name"
            value={formData.last_name}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            error={!!emailError}
            helperText={emailError}
          />
        </Box>
        <Box mb={2}>
          <PhoneInput
            country={'rs'}
            value={formData.phone}
            onChange={handlePhoneChange}
            inputStyle={{ width: '100%', height: '56px', fontSize: '16px' }}
            placeholder="Phone"
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            name="address"
            value={formData.address}
            onChange={handleChange}
          />
        </Box>
        <Box mb={2}>
        <TextField
            label="City"
            variant="outlined"
            fullWidth
            margin="normal"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={2}>
          <FormLabel>Country *</FormLabel>
          <Select
            options={countryOptions}
            value={countryOptions.find(option => option.label === formData.country) || null}
            onChange={(option) => handleCountryChange(option, 'country')}
            placeholder="Select Country"
            components={{ Option: CustomOption }}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                minHeight: '56px',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#007bff' : 'white',
                color: state.isSelected ? 'white' : 'black',
                padding: 10,
              }),
            }}
            isClearable
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Date of Birth"
            variant="outlined"
            fullWidth
            margin="normal"
            name="date_of_birth"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.date_of_birth}
            onChange={handleChange}
            required
            inputProps={{ max: maxDateOfBirthString }}
          />
        </Box>
        <Box mb={2}>
          <FormLabel>Professional Status *</FormLabel>
          <Select
            options={professionalStatusOptions}
            value={professionalStatusOptions.find(option => option.value === formData.professional_status) || null}
            onChange={(option) => handleSelectChange(option, 'professional_status')}
            placeholder="Select Professional Status"
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                minHeight: '56px',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#007bff' : 'white',
                color: state.isSelected ? 'white' : 'black',
                padding: 10,
              }),
            }}
            isClearable
            required
          />
        </Box>
        <Box mb={2}>
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Gender</FormLabel>
            <RadioGroup row aria-label="gender" name="gender" value={formData.gender} onChange={handleChange}>
              <FormControlLabel value="m" control={<Radio />} label="Male" />
              <FormControlLabel value="f" control={<Radio />} label="Female" />
            </RadioGroup>
          </FormControl>
        </Box>
        <Divider style={{ margin: '30px 0' }} />
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>2. Account Security</Typography>
          <Tooltip title="Password must be at least 8 characters long" arrow>
            <TextField
                label="Password (min. 8 chars)"
                variant="outlined"
                fullWidth
                margin="normal"
                name="password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={!!passwordError}
                helperText={passwordError}
                required
            />
          </Tooltip>
        </Box>
        <Box mb={2}>
          <Box display="flex" alignItems="center">
            <LinearProgress
              variant="determinate"
              value={(passwordStrength / 4) * 100}
              style={{ backgroundColor: '#d3d3d3', height: '5px', borderRadius: '2px', flex: 1 }}
              sx={{ '& .MuiLinearProgress-bar': { backgroundColor: getPasswordStrengthColor() } }}
            />
            <Typography variant="body2" style={{ marginLeft: 10 }}>{getPasswordStrengthText()}</Typography>
          </Box>
        </Box>
        <Box mb={2}>
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            margin="normal"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            error={!passwordMatch}
            helperText={!passwordMatch && "Passwords do not match"}
          />
        </Box>
        <Divider style={{ margin: '30px 0' }} />
        <Box mb={2}>
          <Typography variant="h6" gutterBottom>3. Passport Information</Typography>
          <FormLabel>Citizenship *</FormLabel>
          <Select
            options={countryOptions}
            value={countryOptions.find(option => option.label === formData.citizenship) || null}
            onChange={(option) => handleCountryChange(option, 'citizenship')}
            placeholder="Select Country"
            components={{ Option: CustomOption }}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                minHeight: '56px',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#007bff' : 'white',
                color: state.isSelected ? 'white' : 'black',
                padding: 10,
              }),
            }}
            isClearable
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Passport Number"
            variant="outlined"
            fullWidth
            margin="normal"
            name="passport_number"
            value={formData.passport_number}
            onChange={handleChange}
            required
          />
        </Box>
        <Box mb={2}>
          <FormLabel>Passport Issued In *</FormLabel>
          <Select
            options={countryOptions}
            value={countryOptions.find(option => option.label === formData.passport_issued_in) || null}
            onChange={(option) => handleCountryChange(option, 'passport_issued_in')}
            placeholder="Select Country"
            components={{ Option: CustomOption }}
            styles={{
              control: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                minHeight: '56px',
              }),
              menu: (provided) => ({
                ...provided,
                backgroundColor: 'white',
                zIndex: 9999,
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? '#007bff' : 'white',
                color: state.isSelected ? 'white' : 'black',
                padding: 10,
              }),
            }}
            isClearable
            required
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Passport Expires"
            variant="outlined"
            fullWidth
            margin="normal"
            name="passport_expires"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={formData.passport_expires}
            onChange={handleChange}
            required
            inputProps={{ min: today }}
          />
        </Box>
        <Box mt={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register
          </Button>
        </Box>
        <Box mt={2} textAlign="center">
          <Typography variant="body2">
            Already have an account? <a href="/login">Login here</a>
          </Typography>
        </Box>
      </form>
      </Box>
    </AuthLayout>
  );
};

const RegisterWithReCaptcha = () => (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
      <Register />
    </GoogleReCaptchaProvider>
  );
  
  export default RegisterWithReCaptcha;
