import React, { useEffect, useRef } from 'react';

const ConstellationBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas not found');
      return;
    }

    const ctx = canvas.getContext('2d');

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight/4;
    };

    setCanvasSize();

    const stars = [];
    const numStars = 80;
    const maxLineLength = 150;
    const starColor = 'black';

    function randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function Star(x, y) {
      this.x = x;
      this.y = y;
      this.size = Math.random() * 1.5;
      this.speedX = (Math.random() * 0.3) - 0.15; // Reduce movement speed
      this.speedY = (Math.random() * 0.3) - 0.15; // Reduce movement speed
    }

    Star.prototype.update = function () {
      this.x += this.speedX;
      this.y += this.speedY;

      if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
      if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;
    };

    Star.prototype.draw = function () {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
      ctx.fillStyle = starColor;
      ctx.fill();
    };

    function createStars() {
      for (let i = 0; i < numStars; i++) {
        stars.push(new Star(randomInt(0, canvas.width), randomInt(0, canvas.height)));
      }
    }

    function connectStars() {
      for (let i = 0; i < stars.length; i++) {
        for (let j = i + 1; j < stars.length; j++) {
          const dx = stars[i].x - stars[j].x;
          const dy = stars[i].y - stars[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < maxLineLength) {
            ctx.beginPath();
            ctx.moveTo(stars[i].x, stars[i].y);
            ctx.lineTo(stars[j].x, stars[j].y);
            ctx.strokeStyle = `rgba(211, 211, 211, ${1 - distance / maxLineLength})`;
            ctx.stroke();
          }
        }
      }
    }

    function animate() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      stars.forEach(star => {
        star.update();
        star.draw();
      });
      connectStars();
      requestAnimationFrame(animate);
    }

    createStars();
    animate();

    window.addEventListener('resize', () => {
      setCanvasSize();
    });

  }, []);

  return (
    <canvas 
      ref={canvasRef} 
      style={{ 
        position: 'absolute', 
        bottom: 0, 
        left: 0, 
        zIndex: -1 
      }} 
    />
  );
};

export default ConstellationBackground;
