import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CircularProgress, Avatar, Button, Modal, Divider } from '@mui/material';
import { CheckCircleOutline, HighlightOff } from '@mui/icons-material';
import API from '../services/api';

const QRValidationPage = () => {
  const { requestId } = useParams();
  const [validationData, setValidationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filePreviews, setFilePreviews] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [smsCode, setSmsCode] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    const validateQRCode = async () => {
      try {
        const response = await API.get(`/request/validate/${requestId}`);
        setValidationData(response.data);

        if (response.data) {
          const personalPhoto = response.data.request.personal_photo;

          if (personalPhoto) {
            const imageResponse = await API.get(`/request/public/${requestId}/personal_photo`, {
              responseType: 'blob',
            });
            setFilePreviews({ personal_photo: URL.createObjectURL(imageResponse.data) });
          }
        }
      } catch (error) {
        console.error('Error validating QR code:', error);
      } finally {
        setLoading(false);
      }
    };

    validateQRCode();
  }, [requestId]);

  const handleSMSVerification = async () => {
    try {
      const response = await API.get(`/request/sms-verify/${requestId}`);
      // If the request is successful, proceed as usual
      setSmsCode(response.data.code);
  
      setModalContent(
        <>
          <Typography variant="h6" gutterBottom>
            СМС Верификација
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
            Захтевајте од носиоца акредитације да Вам прочита идентичан код из СМС поруке
          </Typography>
          <Typography variant="h4" color="primary">
            {response.data.code}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
            Затвори
          </Button>
        </>
      );
  
      setOpenModal(true);
    } catch (error) {
      if (error.response && error.response.status === 429) {
        // If the status is 429 (Too Many Requests), show the limit reached message
        setModalContent(
          <>
            <Typography variant="h6" gutterBottom>
              СМС Верификација
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
              Дневни лимит смс верификација је достигнут.
              <br/>
              Покушајте поново сутра.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
              Затвори
            </Button>
          </>
        );
  
        setOpenModal(true);
      } else {
        console.error('Error sending SMS verification:', error);
        setModalContent(
          <>
            <Typography variant="h6" gutterBottom>
              СМС Верификација
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
              Дошло је до грешке.
              <br/>
              Систем за верификацију тренутно није доступан.
            </Typography>
            <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
              Затвори
            </Button>
          </>
        );
  
        setOpenModal(true);
      }
    }
  };
  


  const handleBlockchainVerification = async () => {
    setIsVerifying(true); // Start loader
    try {
      const response = await API.get(`/admin/requests/${requestId}/verify-on-blockchain`);
      
      if (response.data.isValid) {
        setModalContent(
          <>
            <Typography variant="h6" gutterBottom>
              Блокчејн Верификација
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
              Акредитација успешно верификована на блокчејну.
            </Typography>
            <Box mt={2}>
              <CheckCircleOutline style={{ color: 'green', fontSize: '48px' }} />
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
                Затвори
              </Button>
            </Box>
          </>
        );
      } else {
        setModalContent(
          <>
            <Typography variant="h6" gutterBottom>
              Блокчејн Верификација
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
              Верификација акредитације није успела.
            </Typography>
            <HighlightOff style={{ color: 'red', fontSize: '48px' }} />
            <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
              Затвори
            </Button>
          </>
        );
      }
    } catch (error) {
      console.error('Error verifying accreditation on blockchain:', error);
      setModalContent(
        <>
          <Typography variant="h6" gutterBottom>
            Блокчејн Верификација
          </Typography>
          <Typography variant="body1" style={{ fontWeight: 300 }} gutterBottom>
            Дошло је до грешке на блокчејн мрежи.
          </Typography>
          <HighlightOff style={{ color: 'red', fontSize: '48px' }} />
          <Button variant="contained" color="primary" onClick={handleCloseModal} style={{ marginTop: '20px' }}>
            Затвори
          </Button>
        </>
      );
    } finally {
      setIsVerifying(false); // Stop loader
      setOpenModal(true); // Open the modal
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!validationData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Card style={{ padding: '20px', textAlign: 'center', maxWidth: '400px', width: '90%' }}>
          <CardContent>
            <Box>
              <HighlightOff style={{ color: 'red', fontSize: '48px' }} />
              <Typography variant="h6" style={{ color: 'red' }}>Акредитација Невалидна</Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh" padding="10px">
      <Card
        style={{
          padding: '15px',
          textAlign: 'center',
          border: '2px solid #ccc',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '360px', // Adjusted for mobile
          position: 'relative',
        }}
      >
        <CardContent>
          <Box display="flex" style={{ position: 'absolute', top: '5px', left: '5px' }}>
            <img src="/logo.png" alt="App Logo" style={{ width: '30px', marginRight: '8px' }} />
            <Typography variant="body2" style={{ fontWeight: 100, textAlign: 'left', marginTop: 5 }}>
              Министарство информисања и телекомуникација
            </Typography>
          </Box>
          <Divider style={{ margin: '20px 0' }} />
          <Avatar
            alt={validationData.request.userId.first_name}
            src={filePreviews.personal_photo}
            style={{ width: '120px', height: '120px', margin: '0 auto' }}
          />
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            {validationData.request.userId.first_name} {validationData.request.userId.last_name}
          </Typography>
          <Typography variant="body1">{validationData.request.editorial_office_name}</Typography>
          <Typography variant="body2" style={{ marginBottom: '10px' }}>
            {validationData.request.editorial_office_country}
          </Typography>
          <Box mt={2}>
            <CheckCircleOutline style={{ color: 'green', fontSize: '48px' }} />
            <Typography variant="h6" style={{ color: 'green' }}>Акредитација је Валидна</Typography>
          </Box>
          <Button variant="contained" color="primary" onClick={handleSMSVerification} style={{ marginTop: '20px' }}>
            Верификуј путем СМС кода
          </Button>
          {/* {(validationData.request.stored_on_blockchain) && (
          <Button variant="contained" color="primary" onClick={handleBlockchainVerification} style={{ marginTop: '20px' }}>
            {isVerifying ? <CircularProgress size={24} /> : 'Верификуј на блокчејну'}
          </Button>
          )} */}
        </CardContent>
      </Card>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          bgcolor="rgba(0, 0, 0, 0.8)"
          p={4}
        >
          <Box
            bgcolor="white"
            p={3}
            borderRadius="8px"
            textAlign="center"
            style={{ maxWidth: '300px', width: '100%' }}
          >
            {modalContent}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default QRValidationPage;
