import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        margin: 0,
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
          width: '100%',
          margin: '0 auto',
        }}
      >
        <img 
          src="/logo-transparent.png" 
          alt="MIT Logo" 
          style={{ 
            height: '40px',
            marginRight: '10px'
          }} 
        />
        <Typography variant="body2" color="text.secondary">
          © {new Date().getFullYear()} |{' '}
          <Link 
            href="https://mit.gov.rs" 
            target="_blank" 
            rel="noopener noreferrer"
            sx={{ 
              color: 'inherit',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Министарство информисања и телекомуникација
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer; 